<!--
#########################################################################################
######    Vista SVC40000  :  RECUENTOS                                             ######
#########################################################################################
-->
<template>
  <div>
      <!-- Top Navigation (Genérico) -->
      <TNC10000></TNC10000>

      <div>{{sTitulo}}</div>
      <div>SVC40000</div>

      <BNC10000></BNC10000>

  </div>
</template>

<script>
import igs_mixin from '@/igs-mixins/igs_mixin'
import TNC10000  from '@/igs-navegacion/TNC10000'
import BNC10000  from '@/igs-navegacion/BNC10000'

export default {
  mixins: [ igs_mixin ],
  components : { TNC10000, BNC10000 },

  data() {
    return {
       //------------------------------------------------------------------------
       //-- Variables Locales
       //------------------------------------------------------------------------
        sTitulo : 'Hola SVC40000'
    }
              

  },

  methods: {

  },

  computed: {

  },

  mounted() {
      //-- Actualizar Módulo "Vivo"
      this.$store.commit('p10StrModuleName', 'SVC40000');

      //-- Actualizar BreadCrumb 
      this.$store.commit('p10StrMenuBC', [
        {
          text: "4. Recuentos",
          active: true
        }
    ]);
  }

}
</script>

<style lang="scss">
</style>
